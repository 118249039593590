<template>
    <div class="main">
        <div class="content">
            <div class="searchBox">
                <div class="">
                    <el-input placeholder="请输入查询内容" v-model="keyword">
                        <template slot="append">
                            <span class="searchbtn" @click="getSearchList">搜索</span>
                        </template>
                    </el-input>
                </div>
            </div>
           
            <div>
                <p style="padding: 20px 0;">本店铺产品搜索引擎排名</p>
                <div v-if="dataList.length==0" class="noData" >
                    <img src="../../../../assets/images/searchNoData.png" alt="">
                    <span>暂无数据</span>
                </div>
                <div class="goodsContent" v-else>
                    <div v-for="item in dataList" class="item">
                        <div class="img">
                            <img :src="item.image" alt="">
                            <span class="rank">排名：{{ item.rank }}</span>
                        </div>
                        <div  class="name">
                        {{ item.name }}

                        </div>

                    </div>
                </div>

            </div>
        </div>

    </div>
</template>
<script>
  export default {
        data() {
        return{
            keyword:'',
            dataList:[
            ],
            page:1,
            pageSize:10

        }
    },
    methods:{
        getSearchList(){
            // storeSelectionGoodsRankList
            if(!this.keyword){
                this.$message.warning('请输入关键字查询')
                return
            }
            let params={
                keyword:this.keyword,
            }
            this.$http.axiosGetBy(this.$api.storeSelectionGoodsRankList, params, (res) => {
                console.log(res);
                if(res.code==200){
                    this.dataList=res.data.data
                }

            })
        }

    }
}
</script>
<style scoped lang="scss">
.main{
   width: 100%;
//    height: 1800px;
   padding: 30px;
   box-sizing: border-box;
   background-color: #fff;
   .content{
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    .searchBox{
        width: 100%;
        height: 106px;
        background-color: #F9F9F9;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   }
}
.searchbtn{
    padding: 0 20px;
    // background-color:#FD4446;
    color: #fff;
}
::v-deep .el-input-group__append, .el-input-group__prepend {
        background-color:#FD4446;
        cursor: pointer;
}
::v-deep .el-input-group>.el-input__inner{
    width: 700px;
    height: 50px;
}
.img{
    position: relative;
    width: 220px;
    height: 220px;
    border-radius: 4px;
    // background-color: #FD4446;
    img{
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
    span{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 56px;
       
        background: rgba(0,0,0,0.5);
        border-radius: 0px 0px 0px 4px;
        color: #ffff;
        display: block;
        // line-height: 12px;
        padding: 5px;
    }
}
.item{
    width: 260px;
    height: 310px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 40px;
    border-radius: 4px 4px 4px 4px;
border: 1px solid #E0E2E0;
margin-right: 20px;
}
.name{
    width: 100%;
    display: -webkit-box; /* 使用弹性盒子布局 */
    -webkit-line-clamp: 2; /* 显示的行数 */
    -webkit-box-orient: vertical; /* 盒子的子元素在竖直方向上排列 */
    overflow: hidden; /* 溢出部分隐藏 */
    text-overflow: ellipsis; /* 显示省略号 */
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    margin-top: 15px;
    
}

.goodsContent{
    display: flex;
    flex-wrap: wrap;
    min-height: 750px;
    // overflow-y: auto;
}
.noData{
    width: 100%;
    height: 750px;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    img{
        width: 400px;
        height: 400px;
    }
    
}
</style>